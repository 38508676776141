/* login Sections Starts */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

.alert-cont {
  display: flex;
  justify-content: center!important;
  align-items: center!important;
  position: absolute;
  top: 20px; /* Ajustez cette valeur selon vos besoins */
  width: 100%;
  z-index: 1000; /* S'assurer que l'alerte est au-dessus des autres éléments */
}

.loginMainContainer {
  position: relative; /* Nécessaire pour positionner l'alerte par rapport à ce conteneur */
}
.ant-space-item{
display: flex;
justify-content: center;
}
.steps-home-login {
  background-image: url(../../assets/img/bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0px;
  min-height: 108vh;
}
/*
.steps-head-mid {
  width: 40%;
}
*/
.steps-head-mid {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-signup-header {
  background-color: #17191B;
  height: 65px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginMainContainer {
  width: 40%;
}

.loginFormContainer {
  width: 100%;
  background-color: #ffff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 8px;
  padding: 10px 15px 0px 15px;
  min-height: 650px;
}

.loginFormOnboardingTitle {
  font-size: 14px;
  text-align: center;
  margin-top: 30px;
}

.loginFormOnboardingTitle .loginFormTitle {
  font-size: 24px;
  margin-bottom: 12px;

}

.loginFormOnboardingTitle .loginFormTitle span {
  background: linear-gradient(to right, #F1A300, #CB1000);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: 700;
  font-size: 40px;
  font-family: Gotham pro;
}

.loginFormOnboardingTitle .loginFormTitle p {
  font-size: 40px;
  font-family: Gotham pro;
  font-weight: 400;
  color: #404040;
  margin-bottom: 0;
}

.LoginForm {
  width: 80%!important;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-size: 12px;
  row-gap: 25px;
}

.loginFormPara p {
  font-size: 26px;
  font-weight: 400;
  font-family: Gotham pro;
  color: #404040;
}

.loginTitle{
  margin-top: 20px;
}

.loginTitle h2 {
  text-decoration: underline;
  text-decoration-color: rgb(64, 64, 64);
  color: #404040;
  font-weight: 900;
  font-family: Gotham pro !important;
  font-size: 26px;
}


/*
.loginInputContainer input {
  padding: 5px 5px;
}
.login-input{
  height: 50px;
}
.pass-input{
  height: 50px;
}
*/
.loginInputContainer> label {
 
  font-size: 16px;
  font-weight: 700;
  color: #404040;
  font-family: "Poppins", sans-serif;
}

.loginInputField {
  border: 1px solid #E4E4E4;
  border-radius: 5px;
  padding: 8px;
  margin-top: 4px;
  height: 50px;
}

.loginInputField input {
  outline: none;
  border: none;
  padding-left: 5px;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: #404040;
}
.loginInputField2 {
  border: 1px solid #E4E4E4;
  border-radius: 5px;
  padding: 8px;
  margin-top: 4px;
  height: 50px;
  display: flex;
  justify-content: space-between;
}

.loginInputField2 span{
  cursor: pointer;
}
.loginInputField2 span img{
  width: 18.75px;
  height: 17.5px;
  margin-top: 5px;
}

.loginInputField2 input {
  outline: none;
  border: none;
  padding-left: 5px;
  width: 90%;
  height: 100%;
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: #404040;
}

.additionalAuth {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
  cursor: pointer;
}

.additional-auth-txt {
  font-size: 14px;
  font-weight: 400;
  color: #404040;
  font-family: "Poppins", sans-serif;
}
.additional-auth-txt:hover{
  color:#F1A300;
  transition: color .3s ease-in-out;
}
.loginFormButtons {
  font-size: 14px;
  width: 97%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
}

.loginFormButtons p {
  font-size: 14px;
  font-weight: 400;
  font-family: Gotham pro;
  color: #404040;
}

.loginFormButtons a {
  width: 100%;
}

#loginCheckbox {
  width: 20px;
  height: 20px;
}

.custom-checkbox {
  font-size: 14px;
  font-weight: 400;
  color: #404040;
  font-family: "Poppins", sans-serif !important;
  }
  
  /*
.custom-checkbox:checked::before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  background-color: #E4E4E4;
  position: absolute;
  top: 1px;
  left: 1px;
  border-radius: 2px;
}

*/

.additionalAuth div:first-child {
  display: flex;
  column-gap: 5px;

}

.login {
  width: 100%;
  color: #ffff;
  background-image: linear-gradient(to right, #F1A300, #CB1000);
  border: none;
  padding: 5px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-family: 'gotham pro';
  height: 50px;
  
}

.loginFormSignup {
  width: 100%;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  text-decoration-color: #31302F;
  color: #31302F;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  font-family: Gotham pro;
}
.register-link-cont{
  height: 10vh;
  font-size: 16px!important;
  font-family: Gotham pro!important;
  display: flex;
  justify-content: center;
  padding-top: 2vh;
 
}
.register-link{
  font-size: 16px!important;
  font-family: Gotham pro!important;
  color: #CB1000;
}
.register-link:hover{
  color: #F1A300;
  cursor: pointer;
}
.social-login-button{
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
 
}
.social-login-button .ant-btn-icon{
  margin-right: 20px;
  font-size: 30px;
  color: #f49236;
}

.social-login-button span:nth-of-type(2){
  display: inline-block;
  font-family: 'Gotham pro';
  font-weight: 700;
  font-size: 16px;
  width: 100px;
  text-align: start;
  
}

.social-login-button:hover{
  background-image: linear-gradient(to right, #F1A300, #CB1000);
  color:#FFFFFF;
  font-weight: 500;
}
.social-login-button:hover .ant-btn-icon {
  color: #fff;
}
/* login Sections Ends */


@media (min-width: 280px) and (max-width: 360px) {

  .loginMainContainer {
    width: 95%;
  }

  .loginFormOnboardingTitle {
    font-size: 12px;
  }

  .loginFormOnboardingTitle .loginFormTitle {
    font-size: 18px;

  }

  .loginFormPara p {
    font-size: 13px;
  }

  .additional-auth-txt{
    font-size: 10px;
  }

  .additionalAuth{
    width: 100%;
    padding: 0px 0px;
  }
  .register-link-cont{

    text-align: center;

  }
  .register-link-cont {
   flex-direction: column;
   font-size: 12px!important;
  }
  .spe-left,
  .spe-mid,
  .spe-rigth,
  .cache{
    display: none!important;
  }
}

/* standard mobilephones */
@media (min-width: 360px) and (max-width: 414px) {

  .spe-left,
  .spe-mid,
  .spe-rigth,
  .cache{
    display: none!important;
  }

  .loginMainContainer {
    width: 90%;
  }

  .loginFormOnboardingTitle {
    font-size: 12px;
  }

  .loginFormOnboardingTitle .loginFormTitle {
    font-size: 22px;

  }

  .loginFormPara p {
    font-size: 13px;
  }

  .additional-auth-txt{
    font-size: 10px;
  }

  .additionalAuth{
    width: 100%;
    padding: 0px 0px;
  }
  .register-link-cont{

    text-align: center;

  }
  .register-link-cont {
    flex-direction: column;
    font-size: 12px!important;
   }

}


/* large mobilephones */
@media (min-width: 414px) and (max-width: 600px) {

  .loginMainContainer {
    width: 90%;
  }

  .loginFormOnboardingTitle {
    font-size: 12px;
  }

  .loginFormOnboardingTitle .loginFormTitle {
    font-size: 22px;

  }

  .loginFormPara p {
    font-size: 14px;
  }

  .additional-auth-txt{
    font-size: 10px;
  }

  .additionalAuth{
    width: 100%;
    padding: 0px 0px;
  }
  .spe-left,
  .spe-mid,
  .spe-rigth,
  .cache{
    display: none!important;
  }

}
@media (max-width: 599px){
  .steps-home-login{
    padding-top: 20px;
  }

}
@media (min-width: 600px){
  .steps-home-login{
    padding-top: 100px;
  }

}
/* Tablets */
@media (min-width: 600px) and (max-width: 780px) {

  .loginFormOnboardingTitle {
    font-size: 13px;
  }

  .loginFormOnboardingTitle .loginFormTitle {
    font-size: 22px;

  }

  .additionalAuth {
    width: 100%;
  }

  .loginFormPara p {
    font-size: 16px;
  }

  .additional-auth-txt{
    font-size: 12px;
  }
}

@media screen and (min-width:781px) and (max-width:1439px) {
  .loginFormPara p {
    font-size: 19px;
  }
}

@media screen and (max-width:768px){
  .loginTitle h2 {
    font-size: 18px;
  }
}


/* dekstop */
@media (min-width: 1440px) {
  .loginFormOnboardingTitle {
    font-size: 16px;
  }

  .loginFormOnboardingTitle .loginFormTitle {
    font-size: 26px;

  }



  .additionalAuth {
    /* width: 91%;
    padding: 0px 0px; */
    font-size: 14px;
  }

  .login {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    height: 50px;
    font-weight: 700;
    border: 1.1111px solid #e4e4e4;
  }

  .loginFormButtons a {
    width: 100%;
  }

  .loginInputField2 input{
    width: 95%;
  }

}


@media screen and (min-width:601px) and (max-width:1024px){
  .loginMainContainer{
    width: 70%;
  }
}

@media screen and (min-width:1440px) and (max-width:1600px){
  .loginFormPara p{
    font-size: 20px;
  }
}

/* Login Responsive Ends */