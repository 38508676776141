.regeneration span{
    font-weight: 700;
    font-family: 'gotham pro'!important;
}
.hover-cont{
    position: relative;
}
.mypreview {
    display: block;
    width: 39vw;
    height: auto;
    border-radius: 10px;
    border: 1.17px solid #E0E4EC;
  
  }
.hover-img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: auto;
    opacity: 0;
    transition: .5s ease;
    background-color: #000000;
    border-radius: 10px;
  }

  .web-cont :hover div.hover-img {
    opacity: .8;
    cursor: pointer;
  }
  
  .hover-img-text {
    color: white;
    font-size: 20px;
    position: absolute;
    font-family: gotham pro;
    top: 50%;
    left: 50%;
    z-index: 5;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
/* For very small screen devices (320px and below) */
@media only screen and (max-width: 320px){ 
    body{
        overflow-y: scroll!important;
        height: auto;
    }
    .card{
        margin-top: 0px;
        width: 100%;
        height: 150vh!important;
        display: flex;
        flex-direction: column;
        padding: 2px;
        margin: 0 auto;
        line-height: 1.5em;
        overflow: scroll;
    }
    .preview{
        width: 0px;
        border: 0px solid black;;
    }

    .txt-container{
        padding-left: 15px;
        padding-right: 15px;
        text-align: start;
    }
    .info-title{
        font-size: 18.77px;
        font-weight: 400;
        font-family: gotham pro;
        color: #4C535F;
    }
    .smaller{
        font-size: 16px;
    }
    .info-data{
        font-weight: 100;
        font-size: 18px;
    }
    .so-small{
        font-size: 12px;
        font-weight: lighter;
        color: #666666;
    }
    .open-btn{
        font-weight: 500;
    }
    .open-icn{
        transform: rotate(45deg);
    }
    .sharing-btn{
        height: 35px;
        display: flex
    }
    .sep{
        content: '';
        height: 0px;
        line-height: 0em;
    }
    .drop-cont{
        margin-top: 1em;
        display:flex ;
    }
    .invisible2{
        opacity: 0;
        display: none;
        transition: opacity .4s ease-in-out;
    }
    .icons-with-links{
        margin-top: 8px; 
    }
    .icon-link{
        margin-top: 5px;
    }
    .soc-inp{
        display: flex;
        width: 100%;
        font-family: gotham pro;
        font-weight: 700;
        font-size: 16.42px;
        vertical-align: middle;
        
    }
    .invisible{
        opacity: 0;
        transition: opacity .4s ease-in-out;
    }
    .social-btn{
        font-weight: 700;
        font-family: gotham pro!important;
        font-size: 19.26px;
    }
    .icon-link{
        display: flex;
        align-items: center;
        height: 30px;
    
    }
    .icon-link .txt-link{
         display: flex;
         width: 75%;
         height: 20px;
         overflow: hidden;
    }
    .tuto-link{
        font-weight: 300;
        font-size: x-small;
        color: #E16400;
    }
    .tuto-link:hover{
        color:#F3A900 ;
    }
    .social-mod{
        width: 100%!important;
    }
    div.soc-inp  textarea {
        height: 58px;
        align-content: center;
    }
    
    .mod-title{
        font-size: 20px;
    }
    .mod-txt{
        font-size: 18px;
    }

}

@media only screen and (min-width: 321px) and (max-width: 500px){ 
    body{
        overflow-y: scroll!important;
        height: auto;
    }
    .card{
        margin-top: 0px;
        width: auto;
        height: auto;
        height: 150vh!important;
        display: flex;
        flex-direction: column;
        padding: 2px;
        margin: 0 auto;
        line-height: 1.3em;
    }
    .preview{
        margin-top: 3px;
        width: 180px;
        height: 180px;
        border: 0px solid black;
        object-fit: contain;
        object-position: 15px 0px;
        
    }

    .txt-container{
        margin-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
        text-align: start;
    }
    .info-title{
        font-size: 18.77px;
        font-weight: 400;
        font-family: gotham pro;
        color: #4C535F;
    }
    .smaller{
        font-size: 16px;
    }
    .info-data{
        font-weight: 100;
        font-size: 18px;
    }
    .so-small{
        font-size: 12px;
        font-weight: lighter;
        color: #666666;
    }
    .open-btn{
        font-weight: 500;
    }
    .open-icn{
        transform: rotate(45deg);
    }
    .sharing-btn{
        height: 35px;
        display: flex
    }
    .sep{
        content: ' ';
        height: 0px;
        line-height: 1em;
    }
    .drop-cont{
        margin-top: 1em;
        display:flex ;
    }
    
    .invisible2{
        opacity: 0;
        display: none;
        transition: opacity .4s ease-in-out;
    }
    .icons-with-links{
        margin-top: 8px; 
    }
    .icon-link{
        margin-top: 5px;
    }
    .soc-inp{
        display: flex;
        width: 100%;
        font-family: gotham pro;
        font-weight: 700;
        font-size: 16.42px;
        vertical-align: middle;
    }
    .invisible{
        opacity: 0;
        transition: opacity .4s ease-in-out;
    }
    .social-btn{
        font-weight: 700;
        font-family: gotham pro!important;
        font-size: 19.26px;
    }
    .icon-link{
       
        display: flex;
        align-items: center;
        height: 30px;
    
    }
    .icon-link .txt-link{
         display: flex;
         width: 80%;
         height: 20px;
         overflow: hidden;
    }
    .tuto-link{
        font-weight: 300;
        font-size: small;
        color: #E16400;
    }
    .tuto-link:hover{
        color:#F3A900 ;
    }

    .social-mod{
        width: 100%!important;
    }
    div.soc-inp  textarea {
        height: 58px;
        align-content: center;
    }
    .mod-title{
        font-size: 20px;
    }
    .mod-txt{
        font-size: 18px;
    }
}    
/* Small screen devices (600px and above) */
@media only screen and (min-width: 501px) {
    body{
        overflow-y: scroll!important;
        height: auto;
    }
    .card{
        margin-top: 50px;
        width: 95%;
        min-height: 70vh;
        height: auto;
        display: flex;
        padding: 2px;
        margin: 0 auto;
    }
    .preview{ 
        width: 320px;
        object-fit: contain;
        object-position: 15px 0px;
    }

    .txt-container{
        padding-left: 15px;
        padding-right: 15px;
        text-align: start;
    }
    .info-title{
        font-size: 18.77px;
        font-weight: 400;
        font-family: gotham pro;
        color: #4C535F;
    }
    .smaller{
        font-size: 20px;
    }
    .info-data{
        font-weight: 100;
        font-size: 18px;
    }
    .so-small{
        font-size: 12px;
        font-weight: lighter;
        color: #666666;
    }
    .open-btn{
        font-weight: 500;
    }
    .open-icn{
        transform: rotate(45deg);
    }
    .sharing-btn{
        height: 50px;
        display: flex
    }
    .drop-cont{
        margin-top: 1em;
        display:flex ;
    }
    .invisible2{
        opacity: 0;
        display: none;
        transition: opacity .4s ease-in-out;
    }
    .icons-with-links{
        margin-top: 8px; 
    }
    .icon-link{
        margin-top: 5px;
    }
    .soc-inp {
        display: flex;
        width: 100%;
        font-family: gotham pro;
        font-weight: 700;
        font-size: 16.42px;
      
    }
    .invisible{
        opacity: 0;
        transition: opacity .4s ease-in-out;
    }
    .social-btn{
        font-weight: 700;
        font-family: gotham pro!important;
        font-size: 19.26px;
    }
    .icon-link{
       
        display: flex;
        align-items: center;
        height: 30px;
    
    }
    .icon-link .txt-link{
         display: flex;
         width: 80%;
         height: 20px;
         overflow: hidden;
    }
    .tuto-link{
        font-weight: 300;
        font-size: small;
        color: #E16400;
    }
    .tuto-link:hover{
        color:#F3A900 ;
    }
    .social-mod{
        width: 100%!important;
    }
    .regeneration{
        width: auto;
    }
    div.soc-inp  textarea {
        height: 58px;
        align-content: center;
    }
    .mod-title{
        font-size: 20px;
    }
    .mod-txt{
        font-size: 18px;
    }
}


@media only screen and (min-width: 900px) {
    body{
        overflow-y: scroll!important;
        height: auto;
    }
    .card{
       
        margin-top: 50px;
        width: 65%;
        height: 80vh;
        display: flex;
        padding-top: 10px;
        margin: 0 auto;
    }
    .preview{
        margin-top: 16px;
        width: 56%;
        height: 60vh;
        border: 2px solid black;
        object-fit: cover;
        object-position: 0 0;
    }

    .txt-container{
        padding-left: 15px;
        padding-right: 15px;
        text-align: start;
        width: 100%;
    }
    .info-title{
        font-size: 18.77px;
        font-weight: 400;
        font-family: gotham pro;
        color: #4C535F;
    }
    .smaller{
        font-size: 20px;
    }
    .info-data{
        font-weight: 100;
        font-size: 18px;
    }
    .so-small{
        font-size: 12px;
        font-weight: lighter;
        color: #666666;
    }
    .open-btn{
        font-weight: 500;
    }
    .open-icn{
        transform: rotate(45deg);
    }
    .sharing-btn{
        height: 50px;
        display: flex
    }
    .drop-cont{
        margin-top: 1em;
        display:flex ;
    }
    .invisible2{
        opacity: 0;
        display: none;
        transition: opacity .4s ease-in-out;
    }
    .icons-with-links{
        margin-top: 8px; 
    }
    .icon-link{
        margin-top: 5px;
    }
    .soc-inp{
        display: flex;
        width: 100%;
        display: flex;
        width: 100%;
        font-family: gotham pro;
        font-weight: 700;
        font-size: 16.42px;
        vertical-align: middle;
    }
    .invisible{
        opacity: 0;
        transition: opacity .4s ease-in-out;
    }
    .social-btn{
        font-weight: 700;
        font-family: gotham pro!important;
        font-size: 19.26px;
    }
    .icon-link{
       
        display: flex;
        align-items: center;
        height: 30px;
    
    }
    .icon-link .txt-link{
         display: flex;
         width: auto;
         height: 20px;
         overflow: hidden;
    }
    .tuto-link{
        font-weight: 300;
        font-size: small;
        color: #E16400;
    }
    .tuto-link:hover{
        color:#F3A900 ;
    }
    .social-mod{
        margin-left: 5.5vw;
    }
    .regeneration{
        width: fit-content
    }
    .mod-title{
        
        font-size: 20px;
    }
    .mod-txt{
        font-size: 18px;
    }
}

@media (max-height: 400px) {
    .card{
        width: 100%;
        height: 175px;
        position: relative;
        overflow-y: scroll;
    }
}