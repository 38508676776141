.subscription-history-box {
    margin-top: 20px;
    padding: 20px;
    background-color: rgb(243, 243, 243);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #E0E4EC;
    width: 100%;
    font-family: 'gotham pro', sans-serif;
    font-weight: 700;
}

.subscription-history-box h2 {
    margin-bottom: 15px;
    font-size: 22px;
    font-weight: 500;
    color: #333;
    font-family: 'gotham pro', sans-serif;
    font-weight: 700;
}

.transaction-list {
    list-style: none;
    padding: 0;
}

.transaction-item {
    display: flex;
    align-items: center;
    padding: 15px;
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 5px;
    border-left: 5px solid transparent;
    transition: transform 0.3s ease, border-color 0.3s ease;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.transaction-item:hover {
    transform: translateY(-5px);
    border-color: #E16400; /* Ajout de la couleur à l'effet hover */
}

.transaction-info p {
    margin: 5px 0;
    font-size: 16px;
    color: #333;
    font-family: 'gotham pro', sans-serif;
}

.transaction-info p strong {
    color: #000;
}

.transaction-info {
    flex-grow: 1;
    padding-left: 15px;
}
