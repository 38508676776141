.step-cancel {
    padding: 0;
  }
  
  .cancel-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #ca0e00, #ffb700); /*dégradé horizontal*/
    color: #fff;
    font-family: 'Arial', sans-serif;
    text-align: center;
    padding: 20px;
    width: 100%;
    height: 100%;
    border-radius: 32px;
  }
  
  .cancel-content h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .cancel-content p {
    font-size: 1.2rem;
    margin: 5px 0;
  }
  
  .cancel-spinner {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .cancel-spinner p {
    margin-top: 10px;
    font-size: 1rem;
    opacity: 0.8;
  }
  