@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap");

/* Global */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /*font-family: Space Grotesk;*/
}


.bold {
  font-weight: 800;
}

.uppercase {
  text-transform: uppercase;
}

/* GENERAL */
.general-gradient-color {
  background: linear-gradient(90deg, #f1a300 0%, #cb1000 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.general-link-decoration {
  text-decoration: none;
  color: #000;
  font-family: gotham pro b;
  font-size: 14px;
}

.general-gradient-bg {
  background: linear-gradient(90deg, #f1a300 0%, #cb1000 100%);
}

.gradient-text {
  background: -webkit-linear-gradient(left, #f1a300, #cb1000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: gotham pro;
}

/* Register Sections Starts */

.steps-home-register {
  background-image: url(../../assets/img/bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0px;
  min-height: 108vh;
}

.alert-cont {
  display: flex;
  justify-content: center!important;
  align-items: center!important;
  position: absolute;
  top: 20px; /* Ajustez cette valeur selon vos besoins */
  width: 100%;
  z-index: 1000; /* S'assurer que l'alerte est au-dessus des autres éléments */
}
.signupMainContainer {
  position: relative;
}
.signupMainContainer {
  width: 40%;
}

.signupFormContainer {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 8px;
  padding: 10px 15px 0px 15px;
  min-height: 650px;
}

.signupFormOnboardingTitle {
  font-size: 14px;
  text-align: center;
  margin-top: 30px;
}

.signupFormOnboardingTitle .signupFormTitle {
  font-size: 24px;
  
}

.signupFormOnboardingTitle .signupFormTitle span {
  background: linear-gradient(to right, #F1A300, #CB1000);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: 700;
  font-size: 40px;
  font-family: gotham pro;
}

.signupFormOnboardingTitle .signupFormTitle p {
  font-size: 40px;
  font-family: gotham pro;
  font-weight: 400;
  color: #404040;
}

.signupFormOnboardingTitle .signupFormPara p {
  font-size: 26px;
  font-weight: 400;
  font-family: gotham pro;
  color: #404040;
  margin-bottom: 0.5em;
}

.signupForm {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-size: 12px;
}

.signupTitle {
  margin-top: 0px;
}

.signupTitle h2 {
  text-decoration: underline;
  text-decoration-color: rgb(64, 64, 64);
  color: #404040;
  font-weight: 400;
  font-family: gotham pro b !important;
  font-size: 26px;
}

.signupInputContainer {
  padding: 0px 0px;
}

.signupInputContainer > label {
  color: #404040;
  font-weight: 700;
  font-size: 16px;
  font-family: gotham pro;
}

.signupInputField {
  border: 1px solid #E4E4E4;
  border-radius: 5px;
  padding: 4px;
  margin-top: 4px;
  height: 50px;
}

.signupInputField input {
  outline: none;
  border: none;
  padding-left: 5px;
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-weight: 400;
  font-family: "gotham pro", sans-serif;
  color: #404040;
}

.signupInputField2 {
  border: 1px solid #E4E4E4;
  border-radius: 5px;
  padding: 4px;
  margin-top: 4px;
  height: 50px;
  display: flex;
  justify-content: space-between;
}

.signupInputField2 span {
  cursor: pointer;
}

.signupInputField2 span img {
  width: 18.75px;
  height: 17.5px;
  margin-top: 5px;
}

.signupInputField2 input {
  outline: none;
  border: none;
  padding-left: 5px;
  width: 90%;
  height: 100%;
  font-size: 14px;
  font-weight: 400;
  font-family: "gotham pro", sans-serif;
  color: #404040;
}

.signupForm .ant-checkbox-wrapper {
  display: flex;
  align-items: center;

}

.signupForm .ant-checkbox-wrapper .ant-checkbox {
  margin-right: 0px;
}

.signupForm .ant-checkbox-wrapper a {
  color: #000;
  font-family: gotham pro b;
  text-decoration: underline;
}

.signupForm .ant-input-password {
  padding: 0;
}

.signupForm .ant-input-password .ant-input {
  border: none;
  outline: none;
  box-shadow: none;
}

.signupForm .ant-input-password .ant-input:hover,
.signupForm .ant-input-password .ant-input:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.signup {
  width: 100%;
  background: linear-gradient(90deg, #f1a300 0%, #cb1000 100%);
  border: none;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-family: gotham pro;
  font-weight: 700;
  height: 50px;
  border: 1px solid #E4E4E4;
}

.signup:hover,
.signup:focus {
  background: linear-gradient(185deg, #cb1000, #f1a300);
  border: none;
  outline: none;
}

.signupFormButtons {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
 
}

.signupFormButtons p {
  font-size: 14px;
  font-weight: 400;
  font-family: gotham pro;
  color: #404040;
  margin-bottom: 0 ;
}

.signupFormButtons a {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
.link-cont{
  text-decoration: none;
  
}
.excistingAccount {
  width: 100%;
  background-color: transparent;
  text-decoration: none;
  border: none;
  color: #CB1000;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  font-family: gotham pro;
}
.link-cont:hover .excistingAccount{
  color: #F1A300;
}
/* Register Sections Ends */

/* Register responsive stars  */

/* small mobile phone */
@media (min-width: 280px) and (max-width: 360px) {
  .signupMainContainer {
    width: 95%;
    
  }

  .signupFormOnboardingTitle .signupFormTitle span {
    font-size: 24px;
  }

  .signupFormOnboardingTitle .signupFormTitle p {
    font-size: 24px;
 
  }

  .signupFormOnboardingTitle .signupFormPara p {
    font-size: 18px;
  }
  .mycaptcha{
    width: 260px;
    overflow: hidden;
  }
}

/* standard mobilephones */
@media (min-width: 360px) and (max-width: 414px) {
  .signupMainContainer {
    width: 90%;
  }

  .signupFormOnboardingTitle .signupFormTitle span {
    font-size: 30px;
  }

  .signupFormOnboardingTitle .signupFormTitle p {
    font-size: 30px;
  }

  .signupFormOnboardingTitle .signupFormPara p {
    font-size: 22px;
  }
}

/* large mobilephones */
@media (min-width: 414px) and (max-width: 600px) {
  .signupMainContainer {
    width: 90%;
  }

  .signupFormOnboardingTitle .signupFormTitle span {
    font-size: 35px;
  }

  .signupFormOnboardingTitle .signupFormTitle p {
    font-size: 35px;
  }

  .signupFormOnboardingTitle .signupFormPara p {
    font-size: 25px;
  }
}
@media (min-width: 600px){
  .steps-home-register{
    padding-top: 100px;
  }
}
/* Tablets */
@media (min-width: 600px) and (max-width: 780px) {
  .steps-home-register{
    padding-top: 100px;
  }
  .signupMainContainer {
    width: 70%;
  }

  .signupFormOnboardingTitle .signupFormTitle span {
    font-size: 35px;
  }

  .signupFormOnboardingTitle .signupFormTitle p {
    font-size: 35px;
  }

  .signupFormOnboardingTitle .signupFormPara p {
    font-size: 26px;
  }
}

/* Desktop */
@media (min-width: 1025px) and (max-width: 1440px) {

  .signupFormOnboardingTitle .signupFormTitle span {
    font-size: 40px;
  }

  .signupFormOnboardingTitle .signupFormTitle p {
    font-size: 40px;
  }

  .signupFormOnboardingTitle .signupFormPara p {
    font-size: 26px;
  }
}
