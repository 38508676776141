@keyframes rotateGradient {
  0% {
    background: linear-gradient(0deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  1% {
    background: linear-gradient(3.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  2% {
    background: linear-gradient(7.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  3% {
    background: linear-gradient(10.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  4% {
    background: linear-gradient(14.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  5% {
    background: linear-gradient(18deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  6% {
    background: linear-gradient(21.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  7% {
    background: linear-gradient(25.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  8% {
    background: linear-gradient(28.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  9% {
    background: linear-gradient(32.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  10% {
    background: linear-gradient(36deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  11% {
    background: linear-gradient(39.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  12% {
    background: linear-gradient(43.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  13% {
    background: linear-gradient(46.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  14% {
    background: linear-gradient(50.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  15% {
    background: linear-gradient(54deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  16% {
    background: linear-gradient(57.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  17% {
    background: linear-gradient(61.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  18% {
    background: linear-gradient(64.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  19% {
    background: linear-gradient(68.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  20% {
    background: linear-gradient(72deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  21% {
    background: linear-gradient(75.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  22% {
    background: linear-gradient(79.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  23% {
    background: linear-gradient(82.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  24% {
    background: linear-gradient(86.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  25% {
    background: linear-gradient(90deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  26% {
    background: linear-gradient(93.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  27% {
    background: linear-gradient(97.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  28% {
    background: linear-gradient(100.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  29% {
    background: linear-gradient(104.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  30% {
    background: linear-gradient(108deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  31% {
    background: linear-gradient(111.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  32% {
    background: linear-gradient(115.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  33% {
    background: linear-gradient(118.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  34% {
    background: linear-gradient(122.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  35% {
    background: linear-gradient(126deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  36% {
    background: linear-gradient(129.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  37% {
    background: linear-gradient(133.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  38% {
    background: linear-gradient(136.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  39% {
    background: linear-gradient(140.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  40% {
    background: linear-gradient(144deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  41% {
    background: linear-gradient(147.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  42% {
    background: linear-gradient(151.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  43% {
    background: linear-gradient(154.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  44% {
    background: linear-gradient(158.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  45% {
    background: linear-gradient(162deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  46% {
    background: linear-gradient(165.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  47% {
    background: linear-gradient(169.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  48% {
    background: linear-gradient(172.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  49% {
    background: linear-gradient(176.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  50% {
    background: linear-gradient(180deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  51% {
    background: linear-gradient(183.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  52% {
    background: linear-gradient(187.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  53% {
    background: linear-gradient(190.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  54% {
    background: linear-gradient(194.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  55% {
    background: linear-gradient(198deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  56% {
    background: linear-gradient(201.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  57% {
    background: linear-gradient(205.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  58% {
    background: linear-gradient(208.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  59% {
    background: linear-gradient(212.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  60% {
    background: linear-gradient(216deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  61% {
    background: linear-gradient(219.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  62% {
    background: linear-gradient(223.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  63% {
    background: linear-gradient(226.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  64% {
    background: linear-gradient(230.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  65% {
    background: linear-gradient(234deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  66% {
    background: linear-gradient(237.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  67% {
    background: linear-gradient(241.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  68% {
    background: linear-gradient(244.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  69% {
    background: linear-gradient(248.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  70% {
    background: linear-gradient(252deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  71% {
    background: linear-gradient(255.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  72% {
    background: linear-gradient(259.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  73% {
    background: linear-gradient(262.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  74% {
    background: linear-gradient(266.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  75% {
    background: linear-gradient(270deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  76% {
    background: linear-gradient(273.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  77% {
    background: linear-gradient(277.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  78% {
    background: linear-gradient(280.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  79% {
    background: linear-gradient(284.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  80% {
    background: linear-gradient(288deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  81% {
    background: linear-gradient(291.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  82% {
    background: linear-gradient(295.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  83% {
    background: linear-gradient(298.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  84% {
    background: linear-gradient(302.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  85% {
    background: linear-gradient(306deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  86% {
    background: linear-gradient(309.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  87% {
    background: linear-gradient(313.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  88% {
    background: linear-gradient(316.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  89% {
    background: linear-gradient(320.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  90% {
    background: linear-gradient(324deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  91% {
    background: linear-gradient(327.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  92% {
    background: linear-gradient(331.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  93% {
    background: linear-gradient(334.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  94% {
    background: linear-gradient(338.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  95% {
    background: linear-gradient(342deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  96% {
    background: linear-gradient(345.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  97% {
    background: linear-gradient(349.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  98% {
    background: linear-gradient(352.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  99% {
    background: linear-gradient(356.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  100% {
    background: linear-gradient(360deg, #F1A300 19.69%, #CB1000 85.02%);
  }
}
.blogo {
  display: block;
}

.slogo {
  display: none;
}
.blogo:hover {
  cursor: pointer;
}
.slogo:hover {
  cursor: pointer;
}
.steps-header {
  background-color: #FFFFFF00;
  height: 65px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px!important;
  position: absolute;
  z-index: 1;

}


.steps-head-left{
  color:#FFFFFF;
  font-weight: 600;
  font-size: 22px;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.steps-head-mid {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
}
.steps-head-mid img {
  max-width:500px;

}
.steps-head-right {
  display: flex;
  justify-content: center;
  width: 30%;
}

.steps-head-right a {
  width: 120px;
  height: 49px;
  border: none;
  font-size: 24px;
  font-weight: 700;
  border-radius: 7.39px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.steps-head-right a {
  text-decoration: none;
}

.gradient-button {
  cursor: pointer;
  border-radius: 4.02px;
  color: white;
  background: linear-gradient(91.07deg, #F1A300 19.69%, #CB1000 85.02%);
  width: auto;
  padding: 10px;
  font-size: 22px;
  font-weight: 700;
}

.gradient-button>a {
  color: white;
  text-decoration: none;
  font-size: 22px;
  font-family: Space grotesk;
}
.gradient-button:hover{
  color: #FFFFFF;
  /*
  animation: rotateGradient 3s linear infinite;
  */
  background: linear-gradient(-45deg, #F1A300 19.69%, #CB1000 85.02%);
}

.gradient-button-logout{
  cursor: pointer;
  border-radius: 7.39px;
  color: white;
  background: linear-gradient(91.07deg, #F1A300 19.69%, #CB1000 85.02%);
  width: 120px;
  height: 49px!important;
  vertical-align: middle;
  font-weight: 700;
  font-size: 22px;
  border: none;
  margin-bottom: 3px;
  margin-right: 43px;

}
.gradient-button-logout:hover{
  color: #FFFFFF!important;
  background: linear-gradient(-45deg, #F1A300 19.69%, #CB1000 85.02%);
}
.setting-icn{
  color: white;
  padding: 10px;
  font-size: 23px;
  vertical-align: middle;
}


@media screen and (max-width:786px) {

  .blogo {
    display: none;
  }

  .slogo {
    display: block;
    width: 60px;
    height: 60px;

  }

  .steps-head-mid {
    width: 100%;
    height: 10vh;
  }
  .steps-header{
   font-size: inherit;
  }
  .steps-head-left {
      display: none!important;
  }

  .steps-head-mid {
      width: 70%;
      justify-content: flex-start!important;
  }
  .block-right {
    display: flex;
    justify-content:flex-end;
    width: 100%;
  }
  .steps-head-right{
    min-width: 200px;
    display: flex;
    width: 80%;
  }


  .steps-head-right {
      display: flex;
      justify-content: flex-end;

 
  }
  .steps-head-right a {
    width: 70px;
    height: 43px;
    font-size: 11px;
}
  .steps-head-right button {
      width: 100px;
      height: 50px;
      font-size: 11px;
  }
  .gradient-button-logout{
    margin-right: 0px;
  }
  .gradient-button-logout span{
    font-size:  16px;
  }
}
