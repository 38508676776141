

.menu {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
    transition: color 0.3s ease;
    height: 70px;

  }
  
  .menu:not(.active) {
    overflow: hidden;
  }
  
  .menu p {
    z-index: 2;
    transition: color 0.3s ease;

  }
  .menu-list .menu p {
    min-height: 30px;
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  .footer-menu-list .menu p {
    min-height: 30px;
    margin-top: 19.5px;
    display: flex;
    align-items: center;
    
  }
  .menu:not(.active)::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #f1a300, #cb1000);
    transition: left 0.3s ease;
    z-index: 1;
  }
  
  .menu:not(.active):hover::before {
    left: 0;
  }
  
  .menu:hover p {
    color: #fff !important;
  }
  
  .menu img {
    z-index: 2;
  }
  
  /* Styles spécifiques aux éléments actifs */
  .menu.active::after {
    background: #cb1000;
    content: "";
    position: absolute;
    width: 5px;
    height: 100%;
    top: 0;
    left: -20px; /* Espace entre la bande et l'élément actif */
    border-radius: 0px 10px 10px 0px;
    z-index: 1;
  }
  
  .menu.active p {
    color: white!important; /* Maintient la couleur du texte pour les éléments actifs */
  }
  .logo:hover{
    cursor: pointer;
  }
  .menu-list {
    flex: 1;
    margin: 50px 0;
    width: 100%;
    overflow-y: auto;
    
}

.menu-list .menu p, .logout{
    font-size: 18.47px;
    color: #222222;
    font-weight: 500;
    font-family: "Nunito Sans", sans-serif;
   
 
}

.technical{
    color: #262525!important;
}

.menu.active p{
    color: #F4F4F4;
    font-size: 18.47px;
    font-weight: 500;
    font-family: "Nunito Sans", sans-serif;
}

.footer-menu-list {
    border-top: 0.79px solid #e0e0e0;
    width: 100%;
    margin-bottom: 50px;
    
}

.footer-menu-list>.menu {
    text-align: left;
    padding: 20px 20px 20px 65px;
    margin: 20px;
    border-radius: 10px;
    font-size: 18.47px;
    line-height: 18.47px;
    font-weight: 500;
    color: #262525;
    cursor: pointer;
    font-family: "Nunito Sans", sans-serif;
}



.menu-list>.menu {
    
    padding: 14px;
    margin: 20px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
    font-family: Nonito;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    color: #262525;
    cursor: pointer;
}

.menu.active {
    position: relative;
    background: linear-gradient(to right, #f1a300, #cb1000) !important;
    color: #fff !important;
}

.menu.active img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(2%) hue-rotate(11deg) brightness(105%) contrast(101%);
}

.menu.active::after {
    background: #cb1000;
    content: "";
    position: absolute;
    width: 5px;
    height: 100%;
    top: 0;
    left: -20px;
    border-radius: 0px 10px 10px 0px;
}
.correction {
  margin-top: 0px!important;
}
@media only screen and (max-width: 540px) {


  .footer-menu-list {
      margin-bottom: 0px;
  }

  .footer-menu-list>.menu {
      font-size: 18.47px;
  }

  .menu-list>.menu {
      font-size: 13px;
  }
}
