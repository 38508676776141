

/* landing Footer Section Starts */

.landing-footer-container {
  background: linear-gradient(180deg, #ffffff -1.8%, #d85e0798 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
}

.landing-footer-upper-box {
  padding-top: 20x;
  padding-bottom: 60px;
  display: flex;
  justify-content: space-around;
}

.landing-footer-upper-box-contact {
  order: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-right: 30px;
}

.landing-footer-upper-box-heading {
  font-size: 18.72px;
  margin-bottom: 10px;
  font-weight: 400;
  font-family: gotham pro;
}

.landing-footer-upper-box-para {
  font-size: 14px;
  font-weight: 400;
  font-family: gotham pro b !important;
}

.landing-footer-upper-box-para p{
  font-family: gotham pro b !important;

}

.landing-footer-upper-box-More {
  order: 2;
  margin-right: 30px;
}

.landing-footer-upper-box-tabs {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.landing-footer-upper-box-Weebseat {
  max-width: 300px;
  order: 3;
  margin-right: 150px;
}

.landing-footer-upper-box-Weebseat-about-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landing-footer-upper-box-Weebseat-description {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #000000;
}

.landing-footer-upper-box-Weebseat-social-container {
  justify-content: center;
  display: flex;
}

.landing-footer-upper-box-Weebseat-social-Inner-Box {
  width: 80%;
}

.landing-footer-upper-box-Weebseat-about-weebSeat-image-box {
  margin-bottom: 10px;
}

.landing-footer-upper-box-Weebseat-about-separator {
  width: 100%;
  height: 1px;
  background-color: #00000069;
  margin: 10px 0;
}

.icons-parent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-footer-upper-box-Weebseat-social-icon {
  font-size: 20px;
  color: #000;
  margin: 0 10px;
  text-decoration: none;
}

.landing-footer-upper-box-get-in-touch {
  max-width: 200px;
  order: 4;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.landing-footer-upper-box-get-in-touch-heading-box {
  text-align: right;
  gap: 10px;
}

.landing-footer-lower-box {
  border-top: 1px solid #00000069;
  padding: 20px 0;
  font-size: 12px;
  text-align: center;
  width: 100%;
}

.landing-footer-upper-box-get-in-touch-email-input {
  background-color: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid #000;
  width: 100%;
}

.landing-footer-upper-box-get-in-touch-email-input:focus {
  outline: none;
  border-bottom: 1px solid #000;
}

.landing-footer-upper-box-get-in-touch-email-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.landing-footer-container .general-link-decoration {
  font-family: 'gotham pro'!important;
  font-weight: 700;
}
.landing-footer-container .landing-footer-upper-box-para, .landing-footer-container .landing-footer-upper-box-para p{
  font-family: 'gotham pro'!important;
  font-weight: 700;
}
/* landing Footer Section Ends */






@media only screen and (max-width: 768px) {



  
  /* footer section */
  .landing-footer-upper-box-contact{
    margin-right: 0px;
  }
  .landing-footer-upper-box-More{
    margin-right: 0px;
  }
  .landing-footer-upper-box-Weebseat{
    margin-right: 0px;
  }
  .landing-footer-upper-box-get-in-touch{
    margin-right: 0px;
  }
}



@media screen and (max-width: 1200px) and (min-width: 1000px) {

  /* Footer section Sections Responsive Starts  */
  .landing-footer-upper-box {
    width: 90%;
  }

}

@media screen and (max-width: 1000px) {

  .landing-footer-upper-box {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  .landing-footer-upper-box-Weebseat {
    order: 1;
    margin-right: 0 !important;
  }

  .landing-footer-upper-box-contact {
    order: 2;
    text-align: center;
    margin-top: 10px;
  }

  .landing-footer-upper-box-More {
    order: 3;
    text-align: center;
  }

  .landing-footer-upper-box-get-in-touch {
    order: 4;
  }

  .landing-footer-upper-box-get-in-touch-heading-box {
    text-align: center;
  }

  .landing-footer-upper-box-Weebseat-social-icon {
    margin: 0 5px;
  }

  .landing-footer-upper-box-get-in-touch-email-box {
    align-items: center;
  }



  /* Footer section Sections Responsive Ends */
}






@media(min-width: 992px) and (max-width: 1024px) {
  .servicesPara {
    line-height: 26px;
  }

  .serviceHigligtList {
    font-size: 16px;
    line-height: 26px;
  }
}

@media(min-width: 1024px) and (max-width: 1400px) {
  .servicesPara {
    line-height: 26px;
  }

  .serviceHigligtList {
    font-size: 16px;
    line-height: 26px;
  }
}

/* Terms of Services Media Query Ends */


/* Privacy Policy Media Query Starts */
@media (min-width: 0px) and (max-width: 576px) {
  .privacyTitle h1 {
    font-size: 32px;
  }

  .privacyInfoPara {
    font-size: 12px;
    line-height: 20px;
  }

  .privacyInfoPara2 {
    font-size: 13px;
    line-height: 20px;
  }



  .higlightedListsdata,
  .innerListHeading {
    font-size: 14px;
  }

  .informationContainer ol li::marker,
  .useOfInformationContainer ol li::marker,
  .disclosureInformationContainer ol li::marker,
  .trackingContainer ol li::marker {
    font-size: 14px;
  }

  .contactUsContainer a {
    font-size: 14px;
  }
}

@media (min-width: 576px) and (max-width: 780px) {
  .privacyTitle h1 {
    font-size: 56px;
  }

  .privacyInfoPara {
    font-size: 14px;
    line-height: 24px;
  }

  .privacyInfoPara2 {
    font-size: 15px;
    line-height: 20px;
  }

  .higlightedListsdata,
  .innerListHeading {
    font-size: 16px;
  }

  .informationContainer ol li::marker,
  .useOfInformationContainer ol li::marker,
  .disclosureInformationContainer ol li::marker,
  .trackingContainer ol li::marker {
    font-size: 16px;
  }

  .contactUsContainer a {
    font-size: 16px;
  }

}


@media(min-width: 780px) and (max-width: 992px) {
  .privacyInfoPara {
    font-size: 14px;
    line-height: 24px;
  }

  .privacyInfoPara {
    font-size: 15px;
    line-height: 24px;
  }

  .higlightedListsdata {
    font-size: 16px;
  }
}

@media(min-width: 992px) and (max-width: 1024px) {

  .privacyInfoPara,
  .privacyInfoPara2 {
    line-height: 26px;
  }

  .innerListHeading {
    font-size: 18px;

  }

  .higlightedListsdata,
  .innerListHeading {
    font-size: 16px;
    line-height: 26px;
  }

  .informationContainer ol li::marker,
  .useOfInformationContainer ol li::marker,
  .disclosureInformationContainer ol li::marker,
  .trackingContainer ol li::marker {
    font-size: 16px;
  }

  .jobs-categories {
    width: 80%;
  }
}

@media(min-width: 1024px) and (max-width: 1400px) {

  .privacyInfoPara,
  .privacyInfoPara2 {
    line-height: 26px;
  }

  .innerListHeading,
  .innerListHeading {
    font-size: 18px;

  }

  .informationContainer ol li::marker,
  .useOfInformationContainer ol li::marker,
  .disclosureInformationContainer ol li::marker,
  .trackingContainer ol li::marker {
    font-size: 18px;
  }

  .higlightedListsdata {
    font-size: 16px;
    line-height: 26px;
  }
}

/* Privacy Policy Media Query Ends */
