/* For very small screen devices (320px and below) */
@media only screen and (max-width: 320px){ 
    .fallbox{
        transform: translateY(50px)!important;
        width: 90%;
        height: 5vh;
        opacity: 1;
        transition: all 1s linear;
        margin: 0 auto;
        z-index :2;
        position: fixed;
        
    }
}
@media only screen and (min-width: 321px) and (max-width: 500px){ 
  .fallbox{
    transform: translateY(60px)!important;
    width: 90%;
    opacity: 1;
    height: 8vh;
    transition: all .3s linear;
    position: fixed;
  }

}
   
/* Small screen devices (600px and above) */
@media only screen and (min-width: 501px) {
  .fallbox{
    transform: translateY(20px)!important;
    width: 66%;
    opacity: 1;
    height: 6vh;
    transition: all .3s linear;
    position: fixed;
}
}


@media only screen and (min-width: 900px) {
    .fallbox{
        transform: translateY(20px)!important;
        width: 38%;
        opacity: 1;
        transition: opacity 1s linear;
        height: 5vh;
        text-align: center;
        position: fixed;
    }
}
@media (max-height: 400px) {
  .fallbox{
    transform: translateY(160px)!important;
    width: 90%;
    opacity: 1;
    transition: all 1s linear;
    z-index:5;
    height: 10vh;
    position: fixed;
}
}