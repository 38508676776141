.layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}

.dashboardPaymentSecTitle {
    margin-bottom: 12px;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 8px;
}

.dashboardPaymentSecTitle > p {
    font-size: 18.47px;
    color: #202224;
    font-weight: 500;
    font-family: "Nunito Sans", sans-serif;
}

.dashboardPaymentSecTitle > h1 {
    margin-bottom: 8px;
    font-size: 32px;
    font-weight: 700;
    color: #202224;
    font-family: gotham pro b;
}

.step7 {
    width: 95%;
    background-color: white;
    border-radius: 11.73px;
    padding: 40px 60px 30px 40px !important;
    box-shadow: 0px 0px 23.46px 0px #C2C2C2;
    display: flex;
    justify-content: center;
    flex-direction: column;
    row-gap: 15px;
    position: relative;
    margin-bottom: 30px;
}

.step7 > h1 {
    text-align: center;
    font-size: 30.47px;
    font-weight: 400;
    font-family: gotham pro;
}

.payment-box2 {
    width: 100%;
    padding: 25px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    border: 1px solid #E4E7E9;
    
}

.payment-box2 > h1 {
    font-size: 18px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #191C1F;
    margin-bottom: 5px;
}

.payment-options {
    display: flex;
    justify-content: space-around;
    gap: 4px;
    align-items: center;
    border: 1px solid #E4E7E9;
    padding: 10px;
}
.radio-group{
    display: contents
}
.payment-options img {
    width: 4%;
}

.payment-option-box {
    width: 25%;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.payment-option-box label {
    text-align: center;
    height: 40%;
    margin-top: 10px;
    font-size: 18px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #191C1F;
}

.payment-option-box img {
    width: 30px;
    height: 30px;
}

.payment-option-box > input {
    width: 12px;
    height: 12px;
}

.payment-option-box > input[type='radio']:after {
    width: 12px;
    height: 12px;
    border-radius: 15px;
    top: -2px;
    left: 0px;
    position: relative;
    background-color: white;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid gainsboro;
}

.payment-option-box > input[type='radio']:checked:after {
    width: 10px;
    height: 10px;
    border-radius: 15px;
    top: -4px;
    left: -2px;
    position: relative;
    content: '';
    display: inline-block;
    background-color: white;
    visibility: visible;
    border: 5px solid #2DA5F3;
}

.input-box2 {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
}

.input-box2 > input {
    height: 44px;
    border: 1px solid #E4E7E9;
    outline: none;
    background-color: white;
    padding: 5px 10px;
    font-family: "Poppins";
}

.input-box2 > input::placeholder {
    color: #8f7777 !important;
    font-size: 18px;
}

.input-box2 > label {
    font-size: 18px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #191C1F;
}

.horizontal-input-box {
    display: flex;
    justify-content: space-between;
}

.horizontal-input-box > div {
    width: 48%;
}

.text-parent {
    padding: 5px 0px;
    display: flex;
    row-gap: 10px;
    flex-direction: column;
}

.text-parent > h2:first-child {
    font-size: 18px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #191C1F;
}

.text-parent > h2:last-child {
    font-size: 18px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    color: #191C1F;
}

.text-parent > h2 > span {
    color: #a2a2a3;
    font-family: "Poppins";
}

#orderNotes {
    padding: 10px 25px;
    resize: none;
    height: 100px;
    border: 1px solid #E4E7E9;
    font-family: "Poppins";
}

#orderNotes::placeholder {
    color: #929FA5 !important;
    font-size: 18px;
    font-weight: 400;
    font-family: "Poppins";
}
.BP-cont{
    display: flex;
    padding: 5vh;
    justify-content: center;
    border: 1px solid #e4e7e9;
    flex-direction: column;
    align-items: center;
}
.BP-cont > h2{
    font-family: gotham pro;
    font-weight: 600;
    margin-bottom: 5vh;
}
.BP-cont > div {
    min-width: 21vw;
}
.btn-pay-cont{
    display: flex;
    justify-content: center;
    align-items: center; 
}
.paypal-button-container{
    margin: 0 auto!important;
}


.landing-pricing-description-switch-box{
    display: flex;
    justify-content: space-around;;
    align-items: center;
    gap: 10px;
    background-color: #f5f5f5;
    min-width:312px;
    min-height: 75px;
    border-radius: 35px;
    border: 1px solid #e9e9e9;
  }
  .landing-pricing-description-switch-box-left{
  
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    background: #e9e9e9; /* Couleur de fond initiale */
    transition: background-color 0.5s;
  }
  
  .landing-pricing-description-switch-box-left:hover{
    cursor: pointer;
    background: linear-gradient(90deg, #d8d8d8 0%, #FFFFFF 100%);
    border: 1px solid #5c5c5c;
    transition: background-color 0.5s;
  }
  
  .landing-pricing-description-switch-box-right:hover{
    cursor: pointer;
    background: linear-gradient(90deg, #d8d8d8 0%, #FFFFFF 100%);
    border: 1px solid #5c5c5c;
     transition: background-color 0.5s;
  }
  
  .landing-pricing-description-switch-box-right{
  
    padding: 10px 20px;
    border-radius: 20px;
  }
  .cycle-selected{
    letter-spacing: 1px;
    display: flex;
    width: fit-content;
    padding: 7px 15px;
    border-radius: 30px;
    font-size: 16px;
    color: #FFFFFF;
    border: 1px solid #ff4610!important;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    background: linear-gradient(90deg, #f1a300 0%, #cb1000 100%)!important;
  }




  .landing-pricing-plan-light {
    background: #f5f5f5;
    width: 30%;
  }

  .landing-pricing-plan {
    border: 1px solid #ddd;
    border-radius: 25px;
    width: 410px;
    padding: 20px 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s;
    gap: 20px;
    display: flex;
    min-height: 460px;
    justify-content: space-around;
    flex-direction: column;
  }
  

  
  .landing-pricing-plan-name-dark {
    font-size: 37.98px;
    font-weight: 700;
    color: #4d4d4d;
    margin-bottom: 5px;
    font-family: gotham pro;
  }
  
  .landing-pricing-plan-price-box{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  .landing-pricing-plan-name-dark2 {
    color: #5A5A5A !important;
    font-weight: 400 !important;
    font-size: 37.98px;
    margin-bottom: 5px;
    font-family: gotham pro;
  }
  
  .landing-pricing-plan-name-light {
    font-size: 37.98px;
    font-weight: bold;
    color: #ffffff;
    font-family: gotham pro;
    margin-bottom: 5px;
  }
  
  .landing-pricing-plan-price {
    font-size: 30px;
    color: #4d4d4d;
    font-weight: 600;
    font-family: gotham pro;
  
  }
  
  .landing-pricing-plan-price-light {
    font-size: 22.79px;
    color: #ffffff;
    font-weight: 400;
    font-family: gotham pro;
  }
  
  .landing-pricing-plan-time-dark {
    font-size: 12.6px;
    font-weight: 400;
    color: #4d4d4d;
    opacity: 0.6;
    font-family: gotham pro;
    text-align: start;
    padding-left: 10px;
  }
  
  .landing-pricing-plan-time-light {
    font-size: 12.6px;
    font-weight: 400;
    color: #ffffff;
    font-family: gotham pro;
    opacity: 0.6;
    text-align: start;
    margin-left: 5px;
  }
  .landing-pricing-plan-cycle-light{
    font-size: 12px;
    font-weight: 400;
    font-family: gotham pro;
    color: #4d4d4d;
    margin-top: 15px;
    margin-bottom: 10px;
    opacity: 0.6;
  }
  .landing-pricing-plan-cycle-dark{
    font-size: 12px;
    font-weight: 400;
    font-family: gotham pro;
    color: #4d4d4d;
    margin-top: 15px;
    margin-bottom: 10px;
    opacity: 0.6;
  }
  .landing-pricing-plan-punchline{
    font-size: 12px;
    font-weight: 400;
    font-family: gotham pro;
    color: #4d4d4d;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .gras{
    font-weight: 600;
  }
  .landing-pricing-plan-separator {
    width: 100%;
    height: 1px;
    background-color: #4d4d4d;
    opacity: 0.3;
    margin-top: 0px;
  }
  
  .landing-pricing-plan-details-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .landing-pricing-plan-detail-box {
    display: flex;
  }
  
  .landing-pricing-plan-detail-check {
    margin-right: 5px;
    display: flex;
  }
  
  .landing-pricing-plan-detail-check img {
    width: 20px;
    height: 20px;
  }
  
  .landing-pricing-plan-detail-para-dark {
    font-size: 16.46px;
    color: #4d4d4d;
    opacity: 0.6;
    text-align: left;
    font-weight: 400;
    font-family: gotham pro;
    margin-bottom:0;
  }
  
  .landing-pricing-plan-detail-para-light {
    font-size: 16.46px;
    color: #ffffff;
    opacity: 0.6;
    text-align: left;
    font-weight: 400;
    font-family: gotham pro;
  }
  
  .landing-pricing-plan-detail-para-dark-highlighted {
    font-size: 16px;
    font-family: gotham pro;
    color: #4d4d4d;
    text-align: left;
  }
  
  .landing-pricing-plan-detail-para-light-highlighted {
    font-size: 16px;
    font-family: gotham pro;
    color: #ffffff;
    text-align: left;
  }

  .landing-pricing-plan-standard-discount {
    display: inline-block;
    border-radius: 20px;
    padding: 8px;
    font-weight: 600;
    color: #fff!important;
  
  }
  .landing-pricing-plan-discount-box{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 25%;
    min-width: 70px;
    min-height: 38px;
  }

  @media only screen and (max-width: 776px) {
    .payment-options{
      border: none;
    }
    .BP-cont{
      border: none;
    }
  }

  @media only screen and (max-width: 660px) {
    .landing-pricing-plan{
      width: auto;
    }
    .landing-pricing-description-switch-box{
      min-width: 250px;
      width: auto;
    }
    .payment-box2{
      border: none;
      padding: 0;
    }
    .payment-options{
      padding: 0;
    }
    .step7{
      
      padding-left: 10px!important;
      padding-right: 10px!important;

    }
  }