.signin-login{
  padding-top: 0!important;
}
.loginFormContainerSignin{
width: 100%;
background-color: #ffff;
display: flex;
flex-direction: column;
justify-content: space-evenly;
align-items: center;
border-radius: 8px;
padding: 10px 15px 0px 15px;
min-height: 415px;
}
.text-addon-signin{
  margin-top: -24px;
  text-align: center;
  text-decoration: underline;
  font-size: 16px;

}
.disclamer{
  display: inline-block;
  margin-bottom: 20px;
  margin-top: 15px;
  font-size: small;
  width: 100%;
  text-align: center;
}

.disclamer a{
  color: #F1A300;
  
}
.disclamer a:hover{
  color: #CB1000;
}
@media (min-width: 280px) and (max-width: 360px) {

}
@media (min-width: 360px) and (max-width: 414px) {

}
@media (min-width: 414px) and (max-width: 600px) {

}

@media (max-width: 599px){}
@media (min-width: 600px){}


  /* Tablets */
  @media (min-width: 600px) and (max-width: 780px) {
  }
  
  @media screen and (min-width:781px) and (max-width:1439px) {

  }
  
  @media screen and (max-width:768px){

  }
  
  
  /* dekstop */
  @media (min-width: 1440px) {
     p.signin-title{
        font-size: 28px!important;
    }
  }

  
  @media screen and (min-width:601px) and (max-width:1024px){
    p.signin-title{
        font-size: 28px!important;
    }
  }
  
  @media screen and (min-width:1440px) and (max-width:1600px){
    .loginFormContainerSignin{
        width: 100%;
        background-color: #ffff;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        border-radius: 8px;
        padding: 10px 15px 0px 15px;
        min-height: 415px;
    }
    p.signin-title{
        font-size: 28px!important;
    }

  }
  