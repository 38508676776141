.payment-box2 h1{
    font-family: gotham pro;
    color: #202224;
}
.payment-box2 h4{
    font-family: gotham pro;
    color: #202224;
}
.tutos{
    background-color: rgb(243, 243, 243);
    border-radius: 8px;
    padding: 5vh;
    margin-top:5vh;
}

.tutos h1{
    font-family: gotham pro b;
    color: #202224;
    margin-bottom: 5vh;
    text-align: center;
}
.tutos h2{
    font-weight: 400;
    font-family: 'gotham pro'!important; 
    color: #202224;
}
.tutos h3{
    font-weight: 400;
    font-family: 'gotham pro'!important; 
    color: #202224;
}
.tutos ul li {
    list-style:inside;
    line-height: 2em;
    font-family: 'gotham pro'!important; 
}
.tutos ol li {
    list-style:inside;
    line-height: 2em;
    font-family: 'gotham pro'!important; 
}
.tutos ul li a, .tutos ol li a {
    text-decoration: none;
    color:rgb(225, 100, 0);
    font-weight: 500;
}
.tutos ul li a:hover{
    color:#cc1500;
    transition: color .3s ease-in-out;
}
.tutos ol li a:hover{
    color:#cc1500;
    transition: color .3s ease-in-out;
}
.tutos p {
    font-family: 'gotham pro'!important; 
    line-height: 1.75em;
}
strong{
    font-weight: 600;
    font-family: 'gotham pro'!important; 
    color: #202224;
}
.reduit{
    padding: 0!important;
}