.stripeSubButton-cont{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.stripeSubButton{
    display: flex;
    padding: 12px 20px;
    font-size: 16px;
    color: #fff;
    background: linear-gradient(90deg, #f1a300 0%, #cb1000 100%);
    border: none;
    border-radius: 20px;
    text-decoration: none;
    vertical-align: middle;
    letter-spacing: 1px;
    border: 1px solid #f5f5f5;
}
.stripeSubButton:hover{
    cursor: pointer;
    border: 1px solid #cb1000;
}
