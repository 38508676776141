

.card-sub-status {
    border: 1px solid #d9d9d9;

}

.card-sub-status .ant-card-body {
    padding: 0;
    font-family: 'gotham pro'!important;
}

.ant-card-body span {
    font-family: 'gotham pro'!important;
    font-size: 19.26px;
    font-weight: 700;
    color: #000;
    line-height: 24px;
    margin-top: -8px;
}

.abo{
    margin-top: 1em;
    font-size: 18px;
}