.roadmap {
    width: 90%;
    height: 20px;
    background: #f0f0f0;
    border-radius: 20px;
    box-shadow: 0.22px 6.19px 4.53px 0px #00000033 inset;
    margin: 10px 0px 70px 0px;
}

.roadmap-responsive-container {
    display: none;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.roadmap-responsive {
    width: 80%;
    height: 20px;
    background: #f0f0f0;
    border-radius: 20px;
    box-shadow: 0.22px 6.19px 4.53px 0px #00000033 inset;
    margin: 10px 0px 70px 0px;
}

.roadmap-flex {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
}

.shadow-circle {
    position: relative;
    width: 40px;
    height: 40px;
    background: #f0f0f0;
    border-radius: 50%;
    box-shadow: 0.22px 3.19px 4.53px 0px #00000033 inset;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background 0.3s ease, box-shadow 0.3s ease;
}

.roadmap-chip {
    position: absolute;
    width: 124px;
    text-align: center;
    top: 60px;
    background: #F0F0F0;
    border-radius: 20px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8C8C8C;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    box-shadow: 0.22px 3.19px 4.53px 0px #00000033 inset;
    transition: background 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

.roadmap-chip.active {
    background: linear-gradient(91.07deg, #F1A300 19.69%, #CB1000 85.02%) !important;
    color: #fff !important;
}

.roadmap-chip::after {
    position: absolute;
    content: '';
    top: -10px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 8px solid #D9D9D9;
}

p.point {
    background: #bfbfbf;
    margin-top: 15px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 50%;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    transition: background 0.3s ease;
}

p.point.active {
    background: linear-gradient(91.07deg, #F1A300 19.69%, #CB1000 85.02%) !important;
}
.onit:hover{
    cursor: pointer;
}

/*Effet de rebond*/
@keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px);
    }
  }
  
.bounce {
  animation: bounce .8s infinite;
}

@media screen and (max-width:786px) {
    .roadmap {
        display: none;
    }

    .roadmap-responsive-container {
        display: flex;
        min-width: 250px;
    }

    .roadmap-responsive {
        width: 85% !important;
        height: 10px;
        box-shadow: 0.22px 3.19px 4.53px 0px #00000033 inset;
        margin: 20px 0px 55px 0px;
    }

    .roadmap-responsive .shadow-circle {
        width: 30px;
        height: 30px;
    }

    .roadmap-responsive .roadmap-chip {
        width: 80px !important;
        height: 20px !important;
        font-size: 12px !important;
        top: 45px !important;
    }

    .roadmap-responsive p.point {
        width: 20px;
        height: 20px;
        font-size: 10px;
        margin-top: 10px;
    }
}
