/* Step4.css */
@keyframes rotateGradient {
  0% {
    background: linear-gradient(0deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  1% {
    background: linear-gradient(3.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  2% {
    background: linear-gradient(7.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  3% {
    background: linear-gradient(10.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  4% {
    background: linear-gradient(14.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  5% {
    background: linear-gradient(18deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  6% {
    background: linear-gradient(21.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  7% {
    background: linear-gradient(25.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  8% {
    background: linear-gradient(28.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  9% {
    background: linear-gradient(32.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  10% {
    background: linear-gradient(36deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  11% {
    background: linear-gradient(39.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  12% {
    background: linear-gradient(43.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  13% {
    background: linear-gradient(46.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  14% {
    background: linear-gradient(50.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  15% {
    background: linear-gradient(54deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  16% {
    background: linear-gradient(57.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  17% {
    background: linear-gradient(61.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  18% {
    background: linear-gradient(64.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  19% {
    background: linear-gradient(68.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  20% {
    background: linear-gradient(72deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  21% {
    background: linear-gradient(75.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  22% {
    background: linear-gradient(79.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  23% {
    background: linear-gradient(82.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  24% {
    background: linear-gradient(86.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  25% {
    background: linear-gradient(90deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  26% {
    background: linear-gradient(93.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  27% {
    background: linear-gradient(97.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  28% {
    background: linear-gradient(100.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  29% {
    background: linear-gradient(104.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  30% {
    background: linear-gradient(108deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  31% {
    background: linear-gradient(111.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  32% {
    background: linear-gradient(115.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  33% {
    background: linear-gradient(118.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  34% {
    background: linear-gradient(122.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  35% {
    background: linear-gradient(126deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  36% {
    background: linear-gradient(129.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  37% {
    background: linear-gradient(133.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  38% {
    background: linear-gradient(136.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  39% {
    background: linear-gradient(140.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  40% {
    background: linear-gradient(144deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  41% {
    background: linear-gradient(147.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  42% {
    background: linear-gradient(151.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  43% {
    background: linear-gradient(154.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  44% {
    background: linear-gradient(158.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  45% {
    background: linear-gradient(162deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  46% {
    background: linear-gradient(165.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  47% {
    background: linear-gradient(169.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  48% {
    background: linear-gradient(172.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  49% {
    background: linear-gradient(176.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  50% {
    background: linear-gradient(180deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  51% {
    background: linear-gradient(183.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  52% {
    background: linear-gradient(187.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  53% {
    background: linear-gradient(190.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  54% {
    background: linear-gradient(194.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  55% {
    background: linear-gradient(198deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  56% {
    background: linear-gradient(201.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  57% {
    background: linear-gradient(205.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  58% {
    background: linear-gradient(208.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  59% {
    background: linear-gradient(212.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  60% {
    background: linear-gradient(216deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  61% {
    background: linear-gradient(219.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  62% {
    background: linear-gradient(223.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  63% {
    background: linear-gradient(226.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  64% {
    background: linear-gradient(230.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  65% {
    background: linear-gradient(234deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  66% {
    background: linear-gradient(237.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  67% {
    background: linear-gradient(241.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  68% {
    background: linear-gradient(244.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  69% {
    background: linear-gradient(248.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  70% {
    background: linear-gradient(252deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  71% {
    background: linear-gradient(255.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  72% {
    background: linear-gradient(259.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  73% {
    background: linear-gradient(262.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  74% {
    background: linear-gradient(266.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  75% {
    background: linear-gradient(270deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  76% {
    background: linear-gradient(273.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  77% {
    background: linear-gradient(277.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  78% {
    background: linear-gradient(280.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  79% {
    background: linear-gradient(284.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  80% {
    background: linear-gradient(288deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  81% {
    background: linear-gradient(291.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  82% {
    background: linear-gradient(295.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  83% {
    background: linear-gradient(298.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  84% {
    background: linear-gradient(302.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  85% {
    background: linear-gradient(306deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  86% {
    background: linear-gradient(309.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  87% {
    background: linear-gradient(313.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  88% {
    background: linear-gradient(316.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  89% {
    background: linear-gradient(320.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  90% {
    background: linear-gradient(324deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  91% {
    background: linear-gradient(327.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  92% {
    background: linear-gradient(331.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  93% {
    background: linear-gradient(334.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  94% {
    background: linear-gradient(338.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  95% {
    background: linear-gradient(342deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  96% {
    background: linear-gradient(345.6deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  97% {
    background: linear-gradient(349.2deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  98% {
    background: linear-gradient(352.8deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  99% {
    background: linear-gradient(356.4deg, #F1A300 19.69%, #CB1000 85.02%);
  }
  100% {
    background: linear-gradient(360deg, #F1A300 19.69%, #CB1000 85.02%);
  }
}
.step4 {
  width: 69%;
  background-color: white;
  border-radius: 32px;
  padding: 30px;
  box-shadow: 13px 16px 7.8px 0px #00000033 inset;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  row-gap: 15px;
  position: relative;
  margin-top: 120px;
}

.step4::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 32px;
  padding: 3px;
  background: linear-gradient(91.07deg, #F1A300 19.69%, #CB1000 85.02%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  animation: rotateGradient 10s linear infinite;
}

.step4 > h1 {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  font-family: gotham pro b;
}

.label-input {
  display: flex;
  column-gap: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.label-input > label {
  font-size: 20px;
  font-weight:1 400;
  width: 135px;
  font-family: gotham pro b;
  color: #000000BA;
  text-align: left;

}

.label-input > div {
  height: 67px;
  width: 55%;
  box-shadow: 0px 0px 4.8px 0px #6C6B69AB;
  background-color: white;
  border-radius: 11px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.label-input > div > input {
  width: 100%;
  border-radius: 11px;
  border: none;
  outline: none;
  height: 100%;
  font-size: 20px;
  padding-left: 4px;
}

.label-input > div > input::placeholder {
  color: #00000033;
  font-size: 20.31px;
  font-family: gotham pro;
}

.label-input > div > input.readonly {
  background-color: #f0f0f0;
  color: #808080;
}

.label-input > div > input.editable {
  background-color: white;
  color: #000000;
}

.label-input > div > div {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.label-input > div > div.editing img {
  filter: brightness(1.5);
}

.gradient-button {
  min-width: 120px;
  height: 49px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 7px;
  margin-bottom: 7px;
  font-weight: 700;
  border-radius: 7.39px;
}

.gradient-button a {
  color: white;
  text-decoration: none;
  font-size: 22px;
  font-family: Space grotesk;
}

@media screen and (max-width: 550px) {
  .step4 {
      width: 94%;
      border-width: 2px;
      box-shadow: 4.22px 5.19px 2.53px 0px #00000033 inset;
      margin-top: 120px;
      padding-left: 20px;
      padding-right: 20px;
     
  }

  .step4 > h1 {
      font-size: 20px;
      font-family: gotham pro b;
  }

  .label-input {
      row-gap: 5px;
  }

  .label-input > div {
      width: 100%;
      height: 49.31px;
  }
  .label-input > label {
    font-size: 18px;
    width: 180px;
  }
  .label-input > div > input::placeholder {
      font-size: 14px;
  }
  .step4 .gradient-button {
    min-width: 100px;
    height: 50px;
    font-size: 16px!important;
  }
  .step4 .gradient-button span{
    font-size: 16px;
  }


  
}
@media screen and (min-width: 551px) and (max-width:786px) {

}