.cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(32, 32, 32, 0.7); /* Couleur de fond */
    color: #fff; /* Couleur du texte */
    padding: 15px;
    text-align: center;
    z-index: 1000;
    font-size: 16px!important;
    font-family: 'gotham pro';
    text-decoration: none;
  }
  
  .cookie-buttons {
    margin-top: 10px;

  }
  
  .accept-button {
    background-color: #ffac33; /* Couleur pour le bouton "Accept" */
    border: none;
  
    margin-right: 10px;
    color: white;
    cursor: pointer;
  }
  
  .decline-button {
    background-color: #bbbab9; /* Couleur pour le bouton "Decline" */
    border: none;

    color: white;
    cursor: pointer;
  }
  
  .link-cookies {
    color: #ffac33;
    font-size: 16px;
    font-family: 'gotham pro';
    text-decoration: none;
    font-weight: 700;
  }