
.ant-upload.ant-upload-select{
    position: relative;
    width: 200px!important;
    height: 200px!important;
    border: 1.26px solid #3E9DB2!important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #ddd!important;
}