.layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    background-color: #f7f8fa;
    width: 100%;
}

.dashboardSupportSecTitle {
    margin-bottom: 20px;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 8px;
}

.dashboardSupportSecTitle > p {
    font-size: 18.47px;
    color: #202224;
    font-weight: 500;
    font-family: "Nunito Sans", sans-serif;
}

.dashboardSupportSecTitle > h1 {
    margin-bottom: 16px;
    font-size: 32px;
    font-weight: 700;
    color: #202224;
    font-family: gotham pro b;
}

.step7 {
    width: 95%;
    background-color: white;
    border-radius: 11.73px;
    padding: 40px 60px 30px 40px;
    box-shadow: 0px 0px 23.46px 0px #C2C2C2;
    display: flex;
    justify-content: center;
    flex-direction: column;
    row-gap: 25px;
    position: relative;
    margin-bottom: 30px;
}

.support-box2 {
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    border: 1px solid #E4E7E9;
    border-radius: 10px;
}

.support-box2 > h1 {
    font-size: 20px;
    font-weight: 600;
    font-family: 'gotham pro';
    color: #191C1F;
    margin-bottom: 15px;
}

.input-box2 {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}

div.techsupport input, div.techsupport  textarea {
    width: 100%;
    padding: 15px;
    font-size: 18px;
    font-family: 'Gotham Pro', sans-serif;
    border: 1.17px solid #E0E4EC;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}
div.techsupport label {
    font-weight: 600;
    font-family: 'gotham pro';
    color: #191C1F;
    font-size: 18px!important;
}

div.techsupport input:focus, div.techsupport textarea:focus {
    border-color: #E16400;
    box-shadow: 0 0 8px rgba(225, 100, 0, 0.5);
    outline: none;
}

.btn-support-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.submit-btn {
    padding: 12px 30px;
    background-color: #E16400;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-btn:hover {
    background-color: #d15300;
}
