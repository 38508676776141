/* App.css */
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

@font-face {
  font-family:"Bungee Spice";
  src: url("BungeeSpice-Regular.ttf") /* TTF file for CSS3 browsers */
}
@font-face {
  font-family:"gotham pro";
  src: url("GothamProRegular.ttf") /* TTF file for CSS3 browsers */
}
@font-face {
  font-family:"gotham pro b";
  src: url("gothampro_black.ttf") /* TTF file for CSS3 browsers */
}
@font-face {
  font-family:"Avenir";
  src: url("AvenirLight.ttf") /* TTF file for CSS3 browsers */
}

.bold {
  font-weight: 800;
}

.uppercase {
  text-transform: uppercase;
}

/* GENERAL */
.general-gradient-color {
  background: linear-gradient(90deg, #f1a300 0%, #cb1000 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.general-link-decoration {
  text-decoration: none;
  color: #000;
  font-family: gotham pro b;
  font-size: 14px;
}

.general-gradient-bg {
  background: linear-gradient(90deg, #f1a300 0%, #cb1000 100%);
}

.gradient-text {
  background: -webkit-linear-gradient(left, #f1a300, #cb1000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: gotham pro;
}







