.otp-txt{
    font-size: medium;
}
.square{
    height: 50px;
    width: 50px;
    font-size: x-large;
    text-align: center;
}
h3{
    font-size: 35px;
}