*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

.steps-home {
    background-image: url(../../assets/img/bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0px;
    min-height: 100vh;
    padding-top: 80px;
}
.step1>h1, .step2>h1, .step3>h1, .step4>h1 {
    font-weight: 600;
}
.step1>h1, .step2>h1, .step3>h1, .step4>h1 {
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    color: #000000;
    font-family: gotham pro b;
}

.fade-in {
    animation: fadeIn 0.2s forwards;
  }
  
  .fade-out {
    animation: fadeOut 0.2s forwards;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
  }

  .error-cont{

    position: fixed;
    width: 40%;
    height: 100px;
    top:100px;
    z-index: 1000;
  }

  .fade-in-down {
    animation: fadeInDown 0.5s forwards;
  }
  
  .fade-out-up {
    animation: fadeOutUp 0.5s forwards;
  }

  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeOutUp {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-20px);
    }
  }