/* For very small screen devices (320px and below) */
/**
 * Media Query for very small screen devices (320px and below)
 * This section defines styles for sharing components when the viewport width 
 * is 320 pixels or smaller.
 *
 * .sharing-cont:
 * - Sets the container width to 40%.
 * - Utilizes flexbox for layout.
 * - Centers the children horizontally.
 *
 * .sharing-btn-cont:
 * - Adds a left margin of 5px.
 * - Sets a fixed height of 40px and width of 80px.
 * - Displays the buttons in a flex container.
 *
 * .sharing-btn-inner-cont:
 * - Sets a fixed width of 80px.
 * - Adds a top padding of -15px.
 * - Displays inner content using flexbox.
 * - Justifies space between children elements.
 * - Sets a fixed height of 25px.
 * - Centers the child elements vertically.
 *
 * .share-icn:
 * - Sets the icon width to 21px.
 *
 * .share-icn:hover:
 * - Applies a zoom effect of 1.05 on hover.
 */
@media only screen and (max-width: 320px){ 
    .sharing-cont{
        width: 40%;
        display: flex;
        justify-content: center;
    }
    .sharing-btn-cont{
        margin-left: 5px;
        height: 40px;
        width: 80px;
        display: flex;
    }
    .sharing-btn-inner-cont{
        width:80px;
        padding-top: -15px;
        display: flex;
        justify-content: space-between;
        height: 25px;
        align-items: center;
    }
    .share-icn{
        width: 21px;
    }
    .share-icn:hover{
        zoom: 1.05;
    }
}
/**
 * Media Query for medium small screen devices (321px to 500px)
 * This section defines styles for sharing components when the viewport width 
 * is between 321 pixels and 500 pixels.
 *
 * .sharing-cont:
 * - Sets the container width to 40%.
 * - Utilizes flexbox for layout.
 * - Centers the children horizontally.
 *
 * .sharing-btn-cont:
 * - Adds a left margin of 5px.
 * - Sets a fixed height of 40px and width of 80px.
 * - Displays the buttons in a flex container.
 *
 * .sharing-btn-inner-cont:
 * - Sets a fixed width of 80px.
 * - Adds a top padding of -15px.
 * - Displays inner content using flexbox.
 * - Justifies space between children elements.
 * - Sets a fixed height of 25px.
 * - Centers the child elements vertically.
 *
 * .share-icn:
 * - Sets the icon width to 21px.
 *
 * .share-icn:hover:
 * - Applies a zoom effect of 1.05 on hover.
 */
@media only screen and (min-width: 321px) and (max-width: 500px){ 
    .sharing-cont{
        width: 40%;
        display: flex;
        justify-content: center;   
    }
    .sharing-btn-cont{
        margin-left: 5px;
        height: 40px;
        width: 80px;
        display: flex;
    }
    .sharing-btn-inner-cont{
        width:80px;
        padding-top: -15px;
        display: flex;
        justify-content: space-between;
        height: 25px;
        align-items: center;
    }
    .share-icn{
        width: 21px;
    }
    .share-icn:hover{
        zoom: 1.05;
    }
}
/* Small screen devices (600px and above) */
/**
 * Media Query for small screen devices (600px and above)
 * This section defines styles for sharing components when the viewport width 
 * is between 501 pixels and 899 pixels.
 *
 * .sharing-cont:
 * - Sets the container width to 40%.
 * - Utilizes flexbox for layout.
 * - Centers the children horizontally.
 *
 * .sharing-btn-cont:
 * - Adds a left margin of 5px.
 * - Sets a fixed height of 50px and width of 80px.
 * - Displays the buttons in a flex container.
 *
 * .sharing-btn-inner-cont:
 * - Sets a fixed width of 80px.
 * - Adds a top padding of 15px.
 * - Displays inner content using flexbox.
 * - Justifies space between children elements.
 * - Sets a fixed height of 25px.
 * - Centers the child elements vertically.
 *
 * .share-icn:
 * - Sets the icon width to 21px.
 *
 * .share-icn:hover:
 * - Applies a zoom effect of 1.05 on hover.
 */
@media only screen and (min-width: 501px) {
    .sharing-cont{
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 25px;
        align-items: center;

    }
    .sharing-btn-inner-cont{
        width:60px;
        padding-top: 5px;
        display: flex;
        justify-content: space-between;
        height: 40px;
        align-items: center;

    }
    .share-icn{
        width: 25px;
    }
    .share-icn:hover{
        zoom: 1.05;
    }
}


/**
 * Media Query for large screen devices (900px and above)
 * This section defines styles for sharing components when the viewport width 
 * is 900 pixels or larger.
 *
 * .sharing-cont: 
 * - Sets the container width to 40%.
 * - Utilizes flexbox for layout.
 * - Centers the children horizontally.
 *
 * .sharing-btn-cont:
 * - Adds a left margin of 5px.
 * - Sets a fixed height of 40px and width of 80px.
 * - Displays the buttons in a flex container.
 *
 * .sharing-btn-inner-cont:
 * - Sets a fixed width of 80px.
 * - Adds a top padding of 5px.
 * - Displays inner content using flexbox.
 * - Justifies space between children elements.
 * - Sets a fixed height of 40px.
 * - Centers the child elements vertically.
 *
 * .share-icn:
 * - Sets the icon width to 21px.
 *
 * .share-icn:hover:
 * - Applies a zoom effect of 1.05 on hover.
 */
@media only screen and (min-width: 900px) {

    .sharing-cont{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: flex-start;

    }
    .sharing-btn-cont{
     
        height: 40px;
        width: 60px;
        display: flex;

    }
    .sharing-btn-inner-cont{
        width:80px;
        padding-top: 5px;
        display: flex;
        justify-content: space-between;
        height: 40px;
        align-items: center;

    }
    .share-icn{
        width: 25px;
    }
    .share-icn:hover{
        zoom: 1.05;
    }
}
